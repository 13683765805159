@import "./theme.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: #161616;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.navbarWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.contentWrapper {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 30px;
}

.footerWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #959595; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #333232; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f1e1e; 
}
