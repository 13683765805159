$size: 200px;
$notchBorderWidth: 15px;

.wrapper {
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: $size;
    font-family: 'stratumBold';
}

.pie {
    width: $size;
    height: $size;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
}

.innerCircle {
    width: calc($size - 40px);
    height: calc($size - 40px);
    background-color: rgb(21, 21, 21);
    border-radius: 50%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outerCircle {
    width: calc($size + 40px);
    height: calc($size + 40px);
    background-color: rgb(21, 21, 21);
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transform: rotateZ(0deg);
    transition: all 8s ease-in-out;
    transition-timing-function: cubic-bezier(0, 0.04, 0.06, 1) !important;
    z-index: 1;
}

.notch {
    border: $notchBorderWidth solid gold;
    width: 0;
    border-right: $notchBorderWidth solid transparent;
    border-left: $notchBorderWidth solid transparent;
    border-bottom: $notchBorderWidth solid transparent;
}