@import '../../../../theme.scss';

.classGold {
    color: $weaponGold;
}

.classRed {
    color: $weaponRed;
}

.classPink {
    color: $weaponPink;
}

.classPurple {
    color: $weaponPurple;
}

.classBlue {
    color: $weaponBlue;
}

.classLightBlue {
    color: $weaponLightBlue;
}

.classWhite {
    color: $weaponWhite;
}