.wrapper {
    border-radius: 10px;
    color: #fff;
    display: flex;
    min-height: 200px;
    padding: 20px;
    background-color: #121212;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
}

.header {
    font-family: 'stratumBold';
}
