@import '../../theme.scss';

.wrapper {
    border-radius: 10px;
    color: #fff;
    margin-top: 20px;
    min-height: 200px;
    padding: 20px;
    background-color: #121212;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
}

.columns {
    display: flex;
    flex-direction: row;
    width: 100%;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
  
.firstColumn {
    flex: 66.66%;
}

.equipmentWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-right: 5px;
    max-height: 700px;
    overflow-y: auto;
}

.secoundColumn {
    flex: 33.33%;
}

.previewWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #161616;
    border: 1px #c5c5c5 solid;
    margin-right: 5px;
    padding: 5px;
    min-height: 140px;
}

.previewDivider {
    background-color: #c5c5c5;  
    width: 70%;
    height: 1px;
    margin: 5px 0;
}

.previewTitle {
    font-family: 'stratumBold';
    text-transform: uppercase;
}

.previewDetails {
    display: flex;
    flex-direction: column;
    width: 70%;
    font-family: 'stratumBold';
    margin-top: 5px;
    text-transform: uppercase;
}

.detailsInfo {
    margin-bottom: 5px;
}

.emptyEquipment {
    display: flex;
    justify-content: center;
    font-family: 'stratumBold';
    font-size: 18px;
}

.statTrackColor {
    color: $statTrakColor;
}

.sellBtnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px 0 10px 0;
}
