$width: 220px;
$height: 280px;

.wrapper {
    width: $width;
    height: $height;
    position: relative;
    background-color: dimgray;
    font-family: 'stratumBold';
    border-radius: 5px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 10px;

    &:hover {
        transform: scale(1.027);
        
        .itemImage {
            opacity: 1;
            backdrop-filter: blur(3px);
        }
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: $width;
    height: $height;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
    overflow: hidden;

    img {
        // width: 100%;
        height: 100%;
    }
}

.info {
    position: absolute;
    top: 0;
    left: 0;
    width: $width;
    height: $height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,1) 100%);
    z-index: 10;
}

.name {
    text-transform: uppercase;
    margin-top: 15px;
}

.price {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    padding-bottom: 10px;
}

.itemImage {
    position: absolute;
    z-index: 9;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .2s ease-in-out;
    background: rgba(135,135,135, 0.5);

    img {
        width: 80%;
    }
}