@import "../../theme.scss";

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    color: #fff;
    display: flex;
    margin-top: 20px;
    min-height: 200px;
    padding: 20px;
    background-color: #121212;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
    max-height: 50%;
}

.loader {
    position: absolute;
}

.usedEqWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.separatorCt,
.separatorTt {
    width: 2px;
    height: 100%;
}

.separatorCt {
    background-color: $defaultCt;
}

.separatorTt {
    background-color: $defaultTt;
}

.weaponsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.weaponsContainer {
    width: calc(5 * 150px);
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.agentImage {
    filter: saturate(0) brightness(0.1) invert(0.5);
    transition: filter 0.2s ease-in-out;
}

.imageWrapper {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        .agentImage {
            filter: saturate(0) brightness(0.1) invert(0.8);
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.ctTeamWrapper,
.imageWrapperCt {
    background-color: rgba($color: $defaultCt, $alpha: .2);
}

.imageWrapperCt:hover {
    background-color: rgba($color: $defaultCt, $alpha: 1);
}

.ttTeamWrapper,
.imageWrapperTt {
    background-color: rgba($color: $defaultTt, $alpha: .2);
}

.imageWrapperTt:hover {
    background-color: rgba($color: $defaultTt, $alpha: 1);
}

.imageWrapperCtActive {
    background-color: rgba($color: $defaultCt, $alpha: 0.5);
}

.imageWrapperTtActive {
    background-color: rgba($color: $defaultTt, $alpha: 0.5);
}

.group {
    display: inline-flex;
    gap: 10px;
}

.groupItem {
    padding: 10px;
    cursor: pointer;
}

.groupItemActive {
    border-bottom: 2px solid White;
}

.myEquipmentWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: calc(100vh - 60px - 60px - 56px - 200px - 130px);
    justify-content: center;
    margin: 20px;
    overflow-y: scroll;
}
