.dividerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "futura-pt";
    font-size: 3em;
    margin: 30px 0;
    color: #fff;
}

.dividerWrapper::before{
    content: '';
    height: 2px;
    background: rgba(255, 255, 255, 0.12);
    flex: 1;
    margin: 0 20px 0 0;
  }
  .dividerWrapper::after{
    content: '';
    height: 2px;
    background: rgba(255, 255, 255, 0.12);
    flex: 1;
    margin: 0 0 0 20px;
  }