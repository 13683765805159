$wrapperSize: 110px;

.wrapper {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 70px;
    gap: 10px;
}

.clickable {
    cursor: pointer;
    
    .defaultImg:hover {
        filter: saturate(0) brightness(0.1) invert(1);
    }
}

.eqItemWrapper {
    flex: 1;
    display: flex;
    position: relative;
    background-color: rgba($color: #1a1a1a, $alpha: .5);
}

.eqItem {
    width: 100%;
    height: 100%;
}

.itemImg,
.defaultImg {
    max-width: 100%;
    max-height: 100%;
}

.defaultImg {
    transition: filter 0.2s ease-in-out;
    filter: saturate(0) brightness(0.1) invert(0.5);
}