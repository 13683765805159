@import '../../../theme.scss';

$defaultHeight: 250px;
$defaultWidth: 200px;
$smallHeight: 150px;
$smallWidth: 150px;

.itemWrapper {
    position: relative;
    width: $defaultWidth;
    height: $defaultHeight;
    margin: 5px;
    cursor: pointer;
    font-family: stratumBold;
    color: whitesmoke;
    background-color: #161616;
    border: 1px solid transparent;
}

.selected {
    border: 1px solid #fff;
}

.itemPreview {
    position: absolute;
    top: 0;
    left: 0;
    width: $defaultWidth;
    height: $defaultHeight;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    transition: all .2s ease-in-out;
}

.descTop {
    text-align: right;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    text-transform: uppercase;
    width: $defaultWidth;
    box-sizing: border-box;
}

.descBottom {
    text-align: left;
    padding-bottom: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    text-transform: uppercase;
    width: $defaultWidth;
    font-size: 10px;
}

.itemName {
    font-size: 14px;
}

.values {
    display: flex;
    gap: 3px;
    margin-left: 10px;
    margin-top: 10px;
}

.itemWear {
    font-size: 12px;
    margin-right: 4px;
}