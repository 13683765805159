.wrapper {
    border-radius: 10px;
    color: #fff;
    margin-top: 20px;
    min-height: 200px;
    padding: 20px;
    background-color: #121212;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
}

.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
  
.column {
    flex: 33.33%;
}

.achivments {
    padding: 10px;
    font-family: 'stratumBold';
    font-size: 24px;
}

.balans {
    padding: 10px;
    font-family: 'stratumBold';
    font-size: 24px;
}

.imgWrapper {
    img {
        border: 2px #e4ac03 solid;
        border-radius: 10px;
        height: 200;
        width: 200;
        margin-right: 20px;
    }
}
