.wrapper {
  display: flex;
  //   height: 100vh;
}

.leftMenuWrapper {
  background-color: #2d2d2d;
  width: 20%;
}

.contentWrapper {
  background-color: rgba(128, 128, 128, 0.438);
  width: 80%;
}
