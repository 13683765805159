$rollerSize: 150px;
$notchBorderWidth: 20px;
$wrapperWidth: calc(10 * $rollerSize);

.wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.notch {
    box-sizing: border-box;
    position: absolute;
    border: $notchBorderWidth solid gold;
    width: 0;
    border-right: $notchBorderWidth solid transparent;
    border-left: $notchBorderWidth solid transparent;
    left: calc(50% - $notchBorderWidth);
    top: 50%;
    z-index: 3;
}

.itemsWrapper {
    position: absolute;
    display: flex;
    transition-timing-function: cubic-bezier(.11,.45,0,1) !important;
}

.itemWrapper {
    position: relative;
    box-sizing: border-box;
}

.wrap {
    height: 100%;
}

.hidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.prizeWrapper {
    position: absolute;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    z-index: 4;
    transition: opacity .2s ease-in-out;
    transition-delay: .1s;
}

.prize {
    height: 95%;
    width: 250px;
    background-color: #161616;
    position: relative;
    border: 1px solid gold;
    box-shadow: 0px 0px 8px gold;
    transition: all .2s ease-in-out;
    transition-delay: .2s;
}

.prizeSelled {
    border: 1px solid gray;
    box-shadow: 0px 0px 8px gray;
}

.prizeDescriptionWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    z-index: 2;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    text-transform: uppercase;
}

.type {
    font-size: 12px;
}

.wear {
    font-size: 12px;
}

.wearFN {
    font-size: 12px;
    color: green;
}

.price {
    color: gold;
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

.goodPrice {
    color: green;
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

.grandPrice {
    color: red;
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

.priceButtonWrapper {
    display: flex;
    align-items: flex-end;
}

.priceButton {
    color: gold;
    display: flex;
    gap: 5px;
    align-items: flex-end;
    border: 1px solid gold;
    padding: 5px;
    box-shadow: 0px 0px 8px gold;
    cursor: pointer;
    transition: all .2s ease-in-out;
    transition-delay: .3s;
}

.goodPrize {
    color: green;
    box-shadow: 0px 0px 8px green;
    border: 1px solid green;
}

.grandPrize {
    color: red;
    box-shadow: 0px 0px 8px red;
    border: 1px solid red;
}

.blocked {
    color: gray;
    border: 1px solid gray;
    box-shadow: 0px 0px 8px gray;
    cursor: no-drop;
}

.statTrack {
    color: orange;
}