.wrapper {
    min-height: 50px;
    overflow: hidden;
}

.groupHeader {
    height: 50px;
    display: flex;
    background-color: white;
    align-items: center;
}

.group {
    margin-top: 0;
    background-color: rgb(203, 203, 203);
    transition: all .2s ease-in-out;
    overflow: hidden;
}

.collapseButton {
    cursor: pointer;
    background-color: white;
    transition: all .2s ease-in-out;

    &:hover {
        background-color: rgb(188, 188, 188);
    }
}

.collapsed {
    margin-top: -25%;
}

.chestItem {
    background-color: rgb(180, 180, 180);
    margin-left: 20px;

    &:nth-child(even) {
        background-color: rgb(130, 130, 130);
    }
}

.actionsIcons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    & > div {
        width: 30px;
    }
}