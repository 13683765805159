@import "../../../../theme.scss";

.wrapper {
  color: white;
  display: block;
  font-family: stratumMedium;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.menuItemWrapper {
  display: block;
  padding: 10px;
  cursor: pointer;
}

.active {
  background-color: $defaultActiveColor;
}