$border-width: 10px;
$item-height: 150px;
$prize-height: 250px;
$prize-width: 220px;
$rollerWrapperWidth: 1500px;

.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    font-family: stratumBold;
}

.carousellWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow: hidden;
    flex-direction: column;
    background-color: #161616;
}

.rollerWrapper {
    // width: $rollerWrapperWidth;
    height: auto;
    background-color: #161616;
    display: flex;
    overflow: hidden;
}

.rollerContainer {
    margin-top: 50px;
    left: -75px;
    position: relative;
}

.rollerContainerTransition {
    transition: all 10s ease-out;
}

.rollerContainerRoll {
    left: -13425px;
}

.roller {
    display: flex;
    position: absolute;
}

.itemWrapper {
    width: 150px;
    height: 150px;
    position: relative;
    border-right: 1px solid black;
    box-sizing: border-box;
}

.notch{
    margin-top: 50px;
    z-index: 99;
    position: absolute;
    top: 0;
    left: calc(50% - ($border-width / 2));
    width: 0px;
    height: calc($item-height - (2 * $border-width));
    border: $border-width solid red;
    border-left: $border-width solid transparent;
    border-right: $border-width solid transparent;
}

.prizePreview {
    z-index: 99;
    position: absolute;
    top: 0;
    left: calc(50% - ($prize-width / 2));
    width: $prize-width;
    height: $prize-height;
    box-sizing: border-box;
    background-color: #161616;
    box-sizing: border-box;
    border: 1px solid gold;
    box-shadow: 0px 0px 8px gold;
    transition: opacity .2s ease-in-out;
}

.buttonsWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 30px;
    position: relative;
}

.blocker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.prizeDescription {
    position: absolute;
    bottom: 0;
    left: 0;
    text-transform: uppercase;
    padding: 0 10px;
    padding-bottom: 5px;
    z-index: 9;
}

.itemImageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
    margin-left: 10px;
}

.type {
    font-size: 11px;
}

.name {
    
}

.wear {
    font-size: 12px;
    
}

.price {
    color: gold;
    display: flex;
    gap: 5px;
}

.overlay {
    width: 100%;
    height: 100%;background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: 0;
    z-index: 3;
    transition: opacity .2s ease-in-out;
}

.awaiting {
    width: 100%;
    height: 100%;background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: 0;
    z-index: 999;
    transition: opacity .2s ease-in-out;
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

.multipleDrawerWrapper {
    display: flex;
    width: 100%;
    background-color: #161616;
    position: relative;
}

.multipleDrawer {
    display: flex;
    justify-content: center;
    border-left: 0px solid black;

    &:nth-child(0){
        border-left: 0px;
    }
}

.multipleRolledItem {
    height: 230px;
    width: 250px;
    max-width: 100%;
    position: relative;
}

.multipleRoller {
    position: absolute;
}

.multiplePrizeWrapper {
    position: absolute;
    top: 5px;
    left: auto;
    height: 240px;
}

.multiplePrizeItemImage {
    height: 230px;
    width: 200px;
}

.sellOneItem {
    position: absolute;
    bottom: 0;
    right: 0;
}

.priceSell {
    display: flex;
    padding: 5px 10px;
    border: 1px solid gold;
    width: auto;
    gap: 3px;
    margin-top: 5px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        border-color: rgb(255, 166, 0);
        color: rgb(255, 166, 0);
    }
}

.disabledSell {
    border: 1px solid gray;
    color: gray;
    cursor: no-drop;

    &:hover {
        border: 1px solid gray;
        color: gray;
    }
}

.statTrack {
    color: orange;
}