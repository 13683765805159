$defaultFontColor: #FFF;
$defaultBackgroundColor: #2b2b2b;
$defaultLinkColor: #fb7531;
$defaultFox: #fb7531;
$defaultActiveColor: #fb7531;

$weaponGold: #e4ac03;
$weaponRed: #ee1e1e;
$weaponPink: #d32ce6;
$weaponPurple: #8847ff;
$weaponBlue: #4b69ff;
$weaponLightBlue: #5e98d9;
$weaponWhite: #7c7c7c;
$defaultCt: #2e80cb;
$defaultTt: #ac912b;

$statTrakColor: #CF6A32;

@font-face {
    font-family: stratumBold;
    src: url('./resources/fonts/stratum2-bold-webfont.woff');
}

@font-face {
    font-family: stratumMedium;
    src: url('./resources/fonts/stratum2-medium-webfont.woff');
}

@font-face {
    font-family: stratumRegular;
    src: url('./resources/fonts/stratum2-regular-webfont.woff');
}