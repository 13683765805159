@import '../../../../theme.scss';

$defaultHeight: 250px;
$defaultWidth: 200px;


.wrapper {
    display: block;
    font-family: stratumMedium;
}

.itemWrapper {
    position: relative;
    width: $defaultWidth;
    height: $defaultHeight;
    margin: 10px;
    cursor: pointer;

    &:hover {
        .itemPreview {
            backdrop-filter: blur(3px);

        }

        .itemPreview > .itemImage {
            transform: scale(1) rotate(15deg);
        }
    }
}

.backdrop {
    background-color: #161616;
    position: absolute;
    top: 0;
    left: 0;
    width: $defaultWidth;
    height: $defaultHeight;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemPreview {
    position: absolute;
    top: 0;
    left: 0;
    width: $defaultWidth;
    height: $defaultHeight;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
}

.itemData {
    position: absolute;
    top: 0;
    left: 0;
    width: $defaultWidth;
    height: $defaultHeight;
    z-index: 2;
    color: white;
}

.backdropBlur {
    position: absolute;
    width: $defaultWidth;
    height: $defaultHeight;
    z-index: 999;
}

.itemImage {
    transform: scale(0.8);
    transition: all .2s ease-in-out;
    width: 180px;
}

.classColor {
    width: 100px;
    height: 100px;
    z-index: 9099;
    transform: rotateZ(45deg);
}

.classGold {
    box-shadow: 0px 0px 30px $weaponGold;
    border: 1px solid $weaponGold;
}

.classRed {
    box-shadow: 0px 0px 30px $weaponRed;
    border: 1px solid $weaponRed;
}

.classPink {
    box-shadow: 0px 0px 30px $weaponPink;
    border: 1px solid $weaponPink;
}

.classPurple {
    box-shadow: 0px 0px 30px $weaponPurple;
    border: 1px solid $weaponPurple;
}

.classBlue {
    box-shadow: 0px 0px 30px $weaponBlue;
    border: 1px solid $weaponBlue;
}

.classLightBlue {
    box-shadow: 0px 0px 30px $weaponLightBlue;
    border: 1px solid $weaponLightBlue;
}

.classWhite {
    box-shadow: 0px 0px 30px $weaponWhite;
    border: 1px solid $weaponWhite;
}

.itemsList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}