$size: 21px;
$color: white;

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(359deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@keyframes bounce {
    0% {
        transform: scale(0.8)
    }
    50% {
        transform: scale(1)
    }
    100% {
        transform: scale(0.8)
    }
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    color: $color;
}

.logoWrapper {
    width: 100px;
    height: 100px;
    animation: rotation 1s infinite linear;
}

.small {
    width: 25px;
    height: 25px;
}

.backgroundWrapper {

}

.background {
    // border: 2px solid $color;
    width: $size;
    height: $size;
    position: absolute;
    top: calc(50% - ($size / 2) - 2px);
    left: calc(50% - ($size / 2) - 1px);
    transform: rotateZ(45deg);
    animation: rotation 1s infinite linear;
}

.isFetching {
    cursor: wait;
}