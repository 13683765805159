.wrapper {
    display: block;
}

.groupWrapper {
    overflow: hidden;
}

.group {
    height: initial;
    background-color: whitesmoke;
}

.collapsed {
    height: 0;
}

.category {
    border-top: 1px solid gray;

    &:nth-child(1) {
        border-top: 0;
    }
}

.addCategory {
    margin: 20px;
    display: flex;
    align-items: center;
}