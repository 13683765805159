html {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
      box-sizing: inherit;
}
