@import '../../../theme.scss';

.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    gap: 20px;
}
  
.column {
    flex: 33.33%;
}

.itemsFloatWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: 'stratumMedium';
     
    & > :hover {
        background-color: rgb(12, 12, 12);
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)
    }
}

.itemFloatBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    color: #fff;
    min-height: 34px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.priceWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121212;
    border-radius: 10px;
    min-height: 260px;
    font-family: 'stratumMedium';
}

.priceRow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;

    & > :first-child {
        margin-right: 10px;
    }
}

.img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.summaryTitle {
    font-weight: bold;
    margin-bottom: 10px;
}

.selectedFloat {
    color: rgb(220 174 100/1);
    font-family: 'stratumBold';
}

.itemName {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px rgba(255, 255, 255, 0.12) solid;
    font-family: 'stratumBold';
}

.classGold {
    background: linear-gradient(180deg, rgba(0,0,0,1) 90%, $weaponGold 100%);
}

.classRed {
    background: linear-gradient(180deg, rgba(0,0,0,1) 90%, $weaponRed 100%);

}

.classPink {
    background: linear-gradient(180deg, rgba(0,0,0,1) 90%, $weaponPink 100%);

}

.classPurple {
    background: linear-gradient(180deg, rgba(0,0,0,1) 90%, $weaponPurple 100%);
}

.classBlue {
    background: linear-gradient(180deg, rgba(0,0,0,1) 90%, $weaponBlue 100%);
}

.classLightBlue {
    background: linear-gradient(180deg, rgba(0,0,0,1) 90%, $weaponLightBlue 100%);
}

.classWhite {
    background: linear-gradient(180deg, rgba(0,0,0,1) 90%, $weaponWhite 100%);
}