@import '../../../theme.scss';

.wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.animated:hover {
    .backgroundBlur {
        backdrop-filter: blur(3px);
    }

    .itemImage {
        transform: scale(1) rotate(15deg);
    }

    .logo {
        transform: scale(0.8);
        opacity: 0.7;
    }

    .classColor {
        transform: scale(0.8) rotate(3 * 45deg);
        opacity: 0.7;
    }
}

.backgroundBlur {
    width: 100%;
    height: 65%;
    position: absolute;
    z-index: 2;
    transition: backdrop-filter .2s ease-in-out;
}

.itemImage {
    width: 100%;
    position: absolute;
    z-index: 3;
    transform: scale(0.8);
    transition: transform .2s ease-in-out;
}

.logo {
    position: absolute;
    width: 50%;
    z-index: 1;
    transition: all .2s ease-in-out;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.classColor {
    width: 50%;
    padding-top: 50%;
    transform: rotateZ(45deg);
    transition: all .2s ease-in-out;
}

.classGold {
    box-shadow: 0px 0px 30px $weaponGold;
    border: 1px solid $weaponGold;
    background-color: rgba($weaponGold, 0.2);
}

.classRed {
    box-shadow: 0px 0px 30px $weaponRed;
    border: 1px solid $weaponRed;
    background-color: rgba($weaponRed, 0.2);
}

.classPink {
    box-shadow: 0px 0px 30px $weaponPink;
    border: 1px solid $weaponPink;
    background-color: rgba($weaponPink, 0.2);
}

.classPurple {
    box-shadow: 0px 0px 30px $weaponPurple;
    border: 1px solid $weaponPurple;
    background-color: rgba($weaponPurple, 0.2);
}

.classBlue {
    box-shadow: 0px 0px 30px $weaponBlue;
    border: 1px solid $weaponBlue;
    background-color: rgba($weaponBlue, 0.2);
}

.classLightBlue {
    box-shadow: 0px 0px 30px $weaponLightBlue;
    border: 1px solid $weaponLightBlue;
    background-color: rgba($weaponLightBlue, 0.2);
}

.classWhite {
    box-shadow: 0px 0px 30px $weaponWhite;
    border: 1px solid $weaponWhite;
    background-color: rgba($weaponWhite, 0.2);
}

.logoClassGold {
    color: $weaponGold;
}

.logoClassRed {
    color: $weaponRed;
}

.logoClassPink {
    color: $weaponPink;
}

.logoClassPurple {
    color: $weaponPurple;
}

.logoClassBlue {
    color: $weaponBlue;
}

.logoClassLightBlue {
    color: $weaponLightBlue;
}

.logoClassWhite {
    color: $weaponWhite;
}