$wrapperSize: 110px;

.wrapper {
    width: $wrapperSize;
    height: $wrapperSize;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        transition: background-color 1s ease-in-out;    
        border: 1px solid rgba(252, 202, 2, 0.5);    
    }
}

.image {
    max-width: 100%;
    max-height: 100%;
}