.wrapper {
    display: flex;
}

.chestName {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.actionsIcons {
    display: flex;
    justify-content: flex-end;
    & > div {
        width: 30px;
    }
}